import ReactDOM from 'react-dom';
import Theme from "./components/theme"
import App from './App';
import { Provider } from 'react-redux'
import store from './redux/store'

ReactDOM.render(
  <Theme>
    <Provider store={store}>
      <App />
    </Provider>
  </Theme>,
  document.querySelector('#root'),
);
