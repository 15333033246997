

class CoreResponse {

  constructor(resp,clazz) {
    this.err = resp?.err;
    this.failure = resp?.failure;
    this.data = resp?.message != null && resp?.message[clazz]
  }

  isValid() { return (this.failure && this.err != null) || (this.data != null) }
  isError() { return (!this.data || this.failure || this.err != null) }
}

export default CoreResponse