import myAxios from "../../utils/myAxios";
import { v4 as uuid } from "uuid"
import { url } from "../../utils/ConnectionUtils";
import { toBytes, toHex } from "hex-my-bytes";

export const KEY_SUCCESS = "KEY_SUCCESS"
export const KEY_FAILURE = "KEY_FAILURE"

export const EP_SUCCESS = "EP_SUCCESS"
export const EP_FAILURE = "EP_FAILURE"

export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"

export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS"
export const AUTHORIZE_FAILURE = "AUTHORIZE_FAILURE"

export const DEAUTHORIZE_SUCCESS = "DEAUTHORIZE_SUCCESS"
export const DEAUTHORIZE_FAILURE = "DEAUTHORIZE_FAILURE"

export const CLEAR_ERROR = "CLEAR_ERROR"
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR"

export const SET_EMPLOYER_DOMAIN = "SET_EMPLOYER_DOMAIN";
export const SET_TEMPLATE_DOMAIN = "SET_TEMPLATE_DOMAIN";

export const authSuccess = person => ({type: AUTH_SUCCESS,payload: person})
export const authFailure = err => ({type: AUTH_FAILURE,payload: err})

export const authorizeSuccess = () => ({type: AUTHORIZE_SUCCESS})
export const authorizeFailure = () => ({type: AUTHORIZE_FAILURE})

export const deauthorizeSuccess = () => ({type: DEAUTHORIZE_SUCCESS})
export const deauthorizeFailure = () => ({type: DEAUTHORIZE_FAILURE})

export const getKeySuccess = key => ({type: KEY_SUCCESS,payload: key})
export const getKeyFailure = err => ({type: KEY_FAILURE,payload: err})

export const endpointSuccess = ep => ({type: EP_SUCCESS,payload: ep})
export const endpointFailure = err => ({type: EP_FAILURE,payload: err})

export const clearError = () => ({ type:CLEAR_ERROR })

export const clearAuthError = () => ({ type:CLEAR_AUTH_ERROR })

export const setEmployerDomain = (empr) => ({ type: SET_EMPLOYER_DOMAIN, payload: empr }) 
export const setTemplateDomain = (template) => ({ type: SET_TEMPLATE_DOMAIN, payload: template })


/**
 * checks for a valid session
 * @returns dispatch
 */
export const authorize = () => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/authorize`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'get'
    })
    dispatch(authorizeSuccess(!response.data?.unauthorized))
  }
}

/**
 * de-authenticates the current session
 * @returns dispatch
 */
export const deauthorize = () => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/authd`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'get'
    })
    if(response.data?.unauthorized)
      sessionStorage.removeItem("pid")
    dispatch(authorizeSuccess(!response.data?.unauthorized))
    setTimeout(window.location.reload(), 1500);
  }
}

/**
 * gets the server public key, used for encrypting simple data, 
 * i.e. passwords or other login credentials.
 * @returns dispatch
 */
export const getKey = () => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/key`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'get'
    })
    if(response.data.failure || response.data.error !== null) {
      dispatch(getKeyFailure(response.data.error || "Oops, something went wrong. Please try again or contact support. ErrorCode #001pk"))
    }else if(response.data.message?.key !== null) {
      dispatch(getKeySuccess(toBytes(response.data.message.key)))
    }else {
      dispatch(getKeyFailure("Oops, something went wrong. Please contact support. ErrorCode #002pk"))
    }
  }
}

/**
 * adds a new Endpoint to represent the current device 
 * running the web application.
 * @param {*} host hostname
 * @param {*} pk publicKey (server key)
 * @returns dispatch
 */
export const addEndpoint = (host,pk) => {
  return async dispatch => {
    let ep = {
      batchId:uuid(),
      endpointId: uuid(),
      endpointKey: pk,
      host: host
    }
    const response = await myAxios({
      baseURL: `${url}/eps/add`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: {
        clearKey: false,
        entity: ep
      }
    })
    if(response.data.failure || response.data.error != null) {
      dispatch(endpointFailure(response.data.error || "Oops, something went wrong. Please try again or contact support. ErrorCode #001ep"))
    }else if(response.data.message?.id != null) {
      sessionStorage.setItem("epid",ep.endpointId)
      dispatch(endpointSuccess(response.data.message.id))
    }else {
      dispatch(endpointFailure("Oops, something went wrong. Please try again or contact support. ErrorCode #002ep"))
    }
  }
}

/**
 * gets an existing Endpoint if one exists by the 
 * endpointId if the value is found on the system
 * in the sessionStorage.
 * @param {*} host hostname
 * @param {*} pk publicKey (server key)
 * @returns dispatch
 */
export const getEndpoint = (host,pk) => {
  return async dispatch => {
    if(sessionStorage.getItem("epid") == null) {
      dispatch(addEndpoint(host,pk))
    }else {
      const response = await myAxios({
        baseURL: `${url}/eps/findby/${sessionStorage.getItem("epid")}`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        responseType: 'json',
        method: 'get'
      })
      if(response.data.failure || response.data.error != null) {
        sessionStorage.removeItem("epid")
        dispatch(endpointFailure(response.data.error || "Oops, something went wrong. Please try again or contact support. ErrorCode #003ep"))
      }else if(response.data.message?.id != null) {
        dispatch(endpointSuccess(response.data.message.id))
      }else {
        dispatch(endpointFailure("Oops, something went wrong. Please try again or contact support. ErrorCode #004ep"))
      }
    }
  }
}

/**
 * initializes the necessary application properties on first run 
 * or during a refresh. Used to clear sessionStorage if a mandatory version update 
 * is found to exist.
 * @returns dispatch
 */
export const initApp = () => {
  return async dispatch => {
    if(!sessionStorage.getItem("etc.tracker.vsn") && sessionStorage.getItem("etc.tracker.vsn") !== process.env.REACT_APP_VERSION) {
      sessionStorage.clear()
      sessionStorage.setItem("etc.tracker.vsn", process.env.REACT_APP_VERSION)
      window.location.reload()
    }
    // if(checkHost) {
    //   let emprDomain,templateDomain;
    //   const host = window.location.host;
    //   const arr = host.split(".")
    //                   .splice(0, host.includes("localhost") ? -1 : -2);
    //   if(arr.length > 1) {
    //     templateDomain = arr[0];
    //     emprDomain = arr[1];
    //   }
    //   dispatch(setEmployerDomain(emprDomain));
    //   dispatch(setTemplateDomain(templateDomain));
    // }
  }
}


 


/**
 * authenticator is the object responsible for 
 * the authentication logic as well as holding on to 
 * the authenticated value. The object presents two 
 * method stubs: signin, and signout to the children 
 * who are passed the object.
 */
 export const authenticator = {
   /**
    * signin authenticates with the server using the 
    * credentials passed in as the person. The key is used 
    * to encrypt the PII that is present. The callback is present 
    * to allow any cleanup code to run after the method has completed.
    * @param {*} person the object representing the Person to authenticate
    * @param {*} key the publicKey (serverKey)
    * @param {*} callback a callback method
    */
  async signin(person, key, callback) {
    const login = async() => {
      //LOGIN LOGIC
      const response = await myAxios({
        baseURL: `${url}/eev/authenticate`,
          crossDomain: true,
          timeout: 200000,
          withCredentials: true,
          responseType: 'json',
          method: 'post',
          data: person
      })
      if(!response.failure && !response.error) {
        callback(response, null)
      }else {
        callback(null,response.error ?? "Whoops, something went wrong. Please try again or contact support. ErrorCode #001a")
      }
    }

    if(person.ssn && person.lastName && person.endpointId) {
      person = {...person, encrypted: false}
      //ATTEMPT TO ENCRYPT SSN
      if(key != null) {
        crypto.subtle.importKey(
          "spki",
          key.buffer,
          {name: 'RSA-OAEP', hash: 'SHA-256'},
          true,
          ['encrypt']
        ).then((cryptKey) => {
          crypto.subtle.encrypt({name:'RSA-OAEP'},cryptKey,stringToArrayBuffer(person.ssn))
          .then((encssn) => {
            person = {...person, encrypted: true, ssn: toHex(new Uint8Array(encssn))}
            login()
          })
          .catch((err) => {
            console.log('encrypt error: ', err)
            callback(null,"Whoops, something went wrong. If the issue persists, please contact support. Error Code #001li")
          })
        }).catch((err) =>  console.log("crypto error: ", err))
      }else {
        login()
      }
    }else {
      setTimeout(callback(null, "Whoops, something went wrong. If the issue persists, please contact support. Error Code #000li"), 100)
    }
    
  },
  /**
   * signout is used to deauthenticate with the server through the 
   * authenticated context.
   * @param {*} callback the callback method
   */
  async signout(callback) {
    setTimeout(callback, 100)
  }
}

/**
 * converts the provided string into an ArrayBuffer
 * @param {*} str provided string
 * @returns ArrayBuffer (Uint8Array)
 */
const stringToArrayBuffer = (str) => {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}