import * as React from 'react';
import {Container,Box,Alert} from '@mui/material';
import Layout from './components/layout';
import { HashRouter as Router, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { authenticator, authorize, authSuccess, clearError, getEndpoint, getKey, initApp } from './redux/actions/authActions'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import { connect } from 'react-redux';
import { Login,TemplateContainer } from './views';
import { hexDecode } from './utils/HexUtils';

const mapStateToProps = state => ({
  pubk: state.auth?.pubk,
  ep: state.auth?.ep,
  error: state.auth?.error,
  authz: state.auth?.authorize,
})

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(initApp()),
  getKey: () => dispatch(getKey()),
  getEndpoint: () => dispatch(getEndpoint()),
  clearError: () => dispatch(clearError()),
  authSuccess: (person) => dispatch(authSuccess(person)),
  checkAuth: () => dispatch(authorize())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  React.useEffect(() => {
    // check for employer and template in subdomains if we haven't yet.
    props.init()
    if(props.error == null) {
      if(props.ep == null) props.getEndpoint(window.location.href, props.pubk)
      else if(props.pubk == null) props.getKey()
      if(sessionStorage.getItem("pid") != null && props.authz == undefined) props.checkAuth()
    }
  })

  return (
    <Layout>
      <Container paddingBottom maxWidth="sm">
        <Box  sx={{ my: 4 }}>
        {props.error != null && 
          <Box pt={5}>
            <Alert mt={10} onClose={props.clearError} severity="error">{props.error}</Alert>
          </Box>
        }
          <Router>
            <AuthProvider>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <Routes>
                  <Route path="/login" element={<Login auth={useAuth} location={useLocation} navigate={useNavigate} />} />
                  <Route 
                    path="/"
                    element={
                      <RequireAuth>
                        {/* LocalizationProvider is needed for DatePicker usage. */}
                        
                          <TemplateContainer />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </LocalizationProvider>
            </AuthProvider>
          </Router>
        </Box>
      </Container>
    </Layout>
  )
})

let AuthContext = React.createContext(null)

const AuthProvider = connect(mapStateToProps,null)((props) => {
  let [authenticated, setAuthenticated] = React.useState(sessionStorage.getItem('pid') != null);

  let signin = (newUser, key, callback) => {
    return authenticator.signin(newUser, key, (response, err) => {
      setAuthenticated(response?.data?.message?.Person);
      callback(response, err);
    });
  };

  let signout = (callback) => {
    return authenticator.signout(() => {
      setAuthenticated(false);
      callback();
    });
  };

  let value = { authenticated, signin, signout };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
})

const useAuth = () => {
  return React.useContext(AuthContext)
}

const RequireAuth = connect(mapStateToProps,mapDispatchToProps)((props) => {
  let auth = useAuth()
  let location = useLocation()
  
  if (!auth.authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }else {
    props.authSuccess(JSON.parse(hexDecode(sessionStorage.getItem("pid"))))
  }

  return props.children;
})