import myAxios from "../../utils/myAxios";
import { url,request } from "../../utils/ConnectionUtils";
import CoreResponse from "../../utils/CoreResponse";

export const RQS_BEGIN = "RQS_BEGIN";
export const RQS_SUCCESS = "RQS_SUCCESS";
export const RQS_FAILURE = "RQS_FAILURE";

export const SET_DIALOG = "SET_DIALOG"
export const CLEAR_DIALOG = "CLEAR_DIALOG"

export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const RESET = "RESET"
export const SET = "SET";


export const reset = () => ({type: RESET})
export const rqsBegin = (clazz) => ({ type: RQS_BEGIN , payload: clazz})
export const rqsSuccess = rqs => ({ type: RQS_SUCCESS, payload: rqs})
export const rqsFailure = err => ({ type: RQS_FAILURE, payload: err})
export const setError = err => ({ type: SET_ERROR, payload: err})
export const clearError = () => ({ type: CLEAR_ERROR})
export const _set = (rqs) => ({ type: SET, payload: rqs})
export const setDialog = (dialog) => ({ type:SET_DIALOG, payload:dialog})
export const clearDialog = () => ({type:CLEAR_DIALOG})

/**
 * props object must contain 2 objects- the name attribute, 
 * with the identifier of 'clazz', and the object, with the identifier 
 * of 'rqs'. Using these two fields, the object passed in is either added 
 * or replaces any current records in the redux store by this identifier.
 * @param {*} props { clazz, rqs }
 * @returns void
 */
export const set = (props) => {
  return async dispatch => {
    if(props.clazz != null) {
      if(props.clazz == 'error') {
        dispatch(setError(props.rqs))
      }else
        dispatch(_set(props))
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #009s",clazz:props?.clazz ?? 'unknown'}));
    }
  }
}

/**
 * gets a valid or non-completed ErqRequest for the provided employeeId and templateId if one exists, 
 * or creates a new one if not.
 * @param {*} props employeeId, templateId
 * @returns dispatch
 */
export const addOrGetErqRequest = ({employeeId, templateId}) => {
  let disp = async dispatch => {
    dispatch(rqsBegin('ErqRequest'))
    let rsp
    if((rsp = new CoreResponse(await request({clazz: "ErqRequest", jsonObj: {templateId: templateId,employeeId: employeeId, submitted: false, completed: false}, path: "cv2", optp: "ALL"}), "ErqRequest")) != null && rsp.isValid()) {
      if(!rsp.isError() && rsp.data.length == 0) {
        //add rqs
        if((rsp = new CoreResponse(await request({clazz: "ErqRequest", jsonObj: {entity: {templateId:templateId,employeeId:employeeId,submitted: false,completed: false}}, path: "cv2", optp: "ADD"}), "ErqRequest")) != null && rsp.isValid()) {
          if(rsp.isError()) {
            dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #001erq",clazz:"ErqRequest"}))
          }else 
            dispatch(rqsSuccess({clazz: "ErqRequest", rqs: rsp.data}))
        }else 
          dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #002erq",clazz:"ErqRequest"}))
      }else 
        dispatch(rqsSuccess({clazz:"ErqRequest", rqs: rsp.data[0]}));
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #004erq",clazz:"ErqRequest"}))
    }
  }
  return disp
}


// export const addOrGetErqProfile = (personId) => {
//   let disp = async dispatch => {
//     dispatch(rqsBegin("ErqProfile"))
//     let rsp 
//     if((rsp = new CoreResponse(await request({clazz: "ErqProfile", jsonObj: {personId: personId}, path: "cv2", optp: "ALL"}), "ErqProfile")) != null && rsp.isValid()) {
//       if(!rsp.isError() && rsp.data.length == 0) {
//         //add ErqProfile
//         if((rsp = new CoreResponse(await request({clazz: "ErqProfile", jsonObj: {}}))))
//       }
//     }
//   }
// }

/**
 * gets a valid ErqRequestStep if one exists for the ErqTemplateOperation and ErqRequest, or creates 
 * a new one if not.
 * @param {*} props operationId, requestId
 * @returns dispatch
 */
export const addOrGetErqRequestStep = (props) => {
  let disp = async dispatch => {
    dispatch(rqsBegin('ErqRequestStep'))
    let rsp
    if((rsp = new CoreResponse(await request({clazz: "ErqRequestStep", jsonObj: props, path: "cv2", optp: "ALL"}), "ErqRequestStep")) != null && rsp.isValid()) {
      if(!rsp.isError() && rsp.data.length == 0) {
        //add rqs
        if((rsp = new CoreResponse(await request({clazz: "ErqRequestStep", jsonObj: {entity: props}, path: "cv2", optp: "ADD"}), "ErqRequestStep")) != null && rsp.isValid()) {
          if(rsp.isError()) {
            dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #001erqst",clazz:"ErqRequestStep"}))
          }else 
            dispatch(rqsSuccess({clazz:"ErqRequestStep", rqs:rsp.data}))
        }else 
          dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #002erqst",clazz:"ErqRequestStep"}))
      }else 
      dispatch(rqsSuccess({clazz:"ErqRequestStep", rqs:rsp.data[0]}))
    }else 
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #004erqst",clazz:"ErqRequestStep"}))
  }
  props.callback && props.callback()
  return disp
}

/**
 * gets an entity type by its id. clazz is the simple classname, jsonObj is the 
 * request object, and path is the package path. In most cases 'cv2' for corvetto.
 * @param {*} props clazz, jsonObj, path
 * @returns dispatch
 */
export const get = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
      dispatch(rqsBegin(props.clazz))
      if((rsp = new CoreResponse(await request({...props, optp: 'IDX'}), props?.clazz)) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #001rq",clazz:props.clazz}))
        else
          dispatch(rqsSuccess({clazz:props.clazz, rqs:rsp.data}));
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0021rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #002rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

/**
 * gets a list of entities represented by the clazz provided, or an empty list if none are found. 
 * @param {*} props clazz, jsonObj, path
 * @returns dispatch
 */
export const getAll = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
      dispatch(rqsBegin(props.clazz))
      if((rsp = new CoreResponse(await request({...props, optp: 'ALL'}), props?.clazz)) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #003rq",clazz:props.clazz}))
        else
          dispatch(rqsSuccess({clazz:props.clazz, rqs:rsp.data}));
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0041rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #004rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

/**
 * adds an entity (jsonObj) by the clazz provided. 
 * @param {*} props clazz, jsonObj, path
 * @returns dispatch
 */
export const add = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
      dispatch(rqsBegin(props.clazz))
      if((rsp = new CoreResponse(await request({...props, optp: 'ADD'}), props?.clazz)) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #005rq",clazz:props.clazz}))
        else
          dispatch(rqsSuccess({clazz:props.clazz, rqs:rsp.data}));
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0061rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #006rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

/**
 * updates an entity (jsonObj) by the clazz provided.
 * @param {*} props clazz, jsonObj, path
 * @returns dispatch
 */
export const update = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
      dispatch(rqsBegin(props.clazz))
      if((rsp = new CoreResponse(await request({...props, optp: 'IDX_UPD'}), props?.clazz)) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #007rq",clazz:props.clazz}))
        else
          dispatch(rqsSuccess({clazz:props.clazz, rqs:rsp.data}));
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0081rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #008rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

/**
 * deactivates an entity (jsonObj) by the clazz provided.
 * @param {*} props clazz, jsonObj, path
 * @returns dispatch
 */
export const remove = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
      dispatch(rqsBegin(props.clazz))
      if((rsp = new CoreResponse(await request({...props, optp: 'IDX_REM'}), props?.clazz)) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #009rq",clazz:props.clazz}))
        else
          dispatch(rqsSuccess({clazz:props.clazz, rqs:rsp.data}));
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0101rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #010rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

export const submitEEVoice = (props) => {
  let disp = async dispatch => {
    let rsp;
    if(props?.jsonObj != null) {
      dispatch(rqsBegin("ErqRequest"))
      if((rsp = new CoreResponse(await request({...props, clazz: 'SubmitEEVoice', path: 'cv2', optp: 'IDX'}), 'ErqRequest')) != null && rsp.isValid()) {
        if(rsp.isError())
          dispatch(rqsFailure({err:rsp.err ?? "Whoops something went wrong. If the problem persists, please contact support. Code #001rq",clazz:props.clazz}))
        else {
          dispatch(rqsSuccess({clazz:'ErqRequest', rqs:rsp.data}));
        }
      }else {
        dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #0021rq",clazz:props.clazz}));
      }
    }else {
      dispatch(rqsFailure({err:"Whoops something went wrong. If the problem persists, please contact support. Code #002rq",clazz:props.clazz}));
    }
  }
  props.callback && props.callback()
  return disp
}

/**
 * addDocument creates a new Document record by the accountId 
 * provided. 
 * @param {*} accountId the accountId 
 * @param {*} formData cv2-document (the file), type-id (documentType id)
 * @param {*} type unused
 * @returns dispatch
 */
export const addDocument = (accountId,formData,type) => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rq/fileupload/${accountId}`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData
    })
    if(response?.data?.message?.ack && response.data?.message?.Document != null) {
      dispatch(addDocumentSuccess({id:response.data?.message.Document,type:type}))
    }else if(response.data?.failure && response.data?.error != null) {
      dispatch(addDocumentFailure(response.data?.error))
    }else {
      dispatch(addDocumentFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000ad"))
    }
  }
}
