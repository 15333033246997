import { CancelRounded, UploadFile } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/lab";
import { Box, Button, Collapse, FormControl, FormHelperText, FormLabel, IconButton, Input, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { createTheme } from '@mui/material/styles'
import { makeStyles, ThemeProvider } from "@mui/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get, getAll, set } from "../../redux/actions/rqsActions";
import { addOrGetErqRequestFieldValue } from "../../utils/ConnectionUtils";

const useStyles = makeStyles ((theme) => ({
  root: {
    whiteSpace: "unset",
    wordBreak: "break-all"
  }
}))

const mapStateToProps = state => ({
  templates: state.rqs?.ErqTemplate,
  activeTemplate: state.rqs?.activeErqTemplate,
  fetching: state.rqs?.fetching
})

const mapDispatchToProps = dispatch => ({
  set: (props) => dispatch(set(props)),
  get: (props) => dispatch(get(props)),
  getAll: (props) => dispatch(getAll(props))
})


export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  const classes = useStyles()

  const [fieldVal, setFieldVal] = useState(null)
  const [files,setFiles] = useState(null)
  const [loading,setLoading] = useState(false)
  const [dob, setDob] = useState(null)

  useEffect(() => {
    if(props.error == null && !loading) {
      //load ErqRequestFieldValue if it is null
      if(fieldVal == null && props.step != null || fieldVal.fieldId != props.field.id) {
        setLoading(true)
        addOrGetErqRequestFieldValue({stepId: props.step.id, fieldId: props.field.id})
        .then((val) => {
          let fieldVal = {...val, field: props.field}
          setFieldVal(fieldVal)
          if(fieldVal?.field?.type == "DATE") {
            setDob(fieldVal?.value)
          }
          props.update(val.id, fieldVal, () => {})
          //IF THE FIELD IS REQUIRED, CHECK VALIDITY, OTHERWISE SET VALIDITY TO TRUE
          props.setValid(props.field.id, props.field.required ? (props.field.type !== 'DOCUMENT' && (val.value != null && val.value.length > 0)) : true)
          setLoading(false)
        }).catch((err) => {
          props.set({clazz:"error",rqs:err.message ?? "Whoops, something went wrong. If the problem persists, please contact support. Code #003pm"})
          props.set({clazz:"fetching",rqs:{...props.fetching, ErqRequestFieldValue: false}})
        })
      }
    }
  })

  /**
   * handler to remove the selected file. Nulls the 
   * file reference, and updates the setValid handler from 
   * parent if necessary. 
   * @param {*} event the button's event
   */
  const handleCancelClick = (event, fileName) => {
    event.preventDefault()
    let map = files ?? new Map()
    map.delete(fileName)
    props.update(fieldVal.id, {...fieldVal, value: null, files: Array.from(map.values())}, () => {
      props.setValid(props.field.id, (files != null && files.size > 0))
      setFiles(new Map(map))
    })
  }

  /**
   * handler used to update the ErqRequestFieldValues when their input changes, 
   * actions depend on the input type, i.e. 'textarea', 'file', etc.
   * Currently only textarea, text, and file are supported.
   * @param {*} event the input's event
   */
  const handleInputChange = (event) => {
    console.log('handleInputChange: event.target.type: ', event)
    //props update method that updates the Map entry for this ErqRequestFieldValue. Callback used to 
    //set this member's valid flags or any other logic
    switch(event?.target?.type) {
      case "textarea":
      case "text": 
        props.update(fieldVal.id, {...fieldVal, value: event.target.value}, () => {
          if(props.field.required) {
            props.setValid(props.field.id, event.target.value != null && event.target.value.length > 0)
          }
          setFieldVal({...fieldVal, value: event.target.value})
        })
      break;
      case "file":
        let _files = event.target.files
        let map = files ?? new Map()
        if(_files != null && _files.length > 0) {
          for(let i=0;i<_files.length;i++) {
            if(map.get(_files[i].name) == null)
              map.set(_files[i].name, _files[i])
          }
        }
        setFiles(new Map(map))
        props.update(fieldVal.id, {...fieldVal, value: event.target.files[0].name, files: Array.from(map.values())}, () => {})
        props.setValid(props.field.id, true)
      break;
      case "select":
        props.update(fieldVal.id, {...fieldVal, value: event.target.value}, () => {
          if(props.field.required) {
            props.setValid(props.field.id, event.target.value != null && event.target.value.length > 0)
          }
          setFieldVal({...fieldVal, value: event.target.value})
        })
      break;
      case "date":
        if(event.target.value != null) {
          //PREVENT UNDEFINED BEING SET FOR FORMATTING ON INPUT
          event.target.value?._d.setHours(0)
          event.target.value?._d.setMinutes(0)
          event.target.value?._d.setSeconds(0)
          console.log('date1: ', event.target.value)
          let now = moment()
          let localOffset = now.utcOffset()
          now.tz("America/Chicago")
          let centralOffset = now.utcOffset()
          let diff = localOffset - centralOffset
          event.target.value?._d?.setHours(event.target.value?._d?.getHours() + (diff/60))
          console.log('date2: ', event.target.value)
        }
        props.update(fieldVal.id, {...fieldVal, value: event.target.value?._d?.toISOString()}, () => {
          if(props.field.required) {
            props.setValid(props.field.id, event.target.value != null)
          }
          setFieldVal({...fieldVal, value: event.target.value?._d?.toISOString()})
        })
      default:
        console.log('default case')
      break;
    }
  }

  return (
    <Box component="form" noValidate>
      <Stack spacing={3} pt={2} xs={12} sm={6} alignItems="left">
        <FormControl error={props.field.required && !props.field.valid}>
          <FormLabel>{props.field.title}</FormLabel>
          {props.field.required && 
            <Collapse in={!props.field.valid}>
              <FormHelperText id={`${props.field.title}-helper-text`}>* {props.field.title} is required.</FormHelperText>
            </Collapse>
          }
        </FormControl>
        {(() => {
          switch(props.field.type) {
            case "STRING":
              return (
                  <TextField
                    error={props.field.required && !props.field.valid}
                    // helperText={(props.field.required && !valid) && `${props.field.title} is a required field.`}
                    margin="normal"
                    required
                    fullWidth
                    id={props.field.name}
                    label={props.field.name}
                    name={props.field.name}
                    autoComplete={props.field.name}
                    autoFocus
                    value={fieldVal?.value}
                    onChange={handleInputChange}
                  />
              )
            case "LARGE_STRING":
              return (
                <>
                  <TextField 
                    error={props.field.required && !props.valid}
                    multiline
                    rows={4}
                    maxRows={6}
                    placeholder={props.field.description}
                    value={fieldVal?.value}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 500 }}
                  />
                  <FormHelperText sx={{'text-align':'right'}}>Max Length: 500 Characters. Remaining: {(500 - (fieldVal?.value?.length || 0))}</FormHelperText>
                </>
              )
            case "STRING_LIST":
              return (
                <>
                  <FormControl mt={0} variant="standard" fullWidth>
                    <InputLabel id="selection-label">{props.field.description}</InputLabel>
                    <Select
                      error={props.field.required && !props.field.valid}
                      labelId="selection-label"
                      label="selection"
                      onChange={(e) => {handleInputChange({...e, target: {...e.target,type: "select"}})}}
                      fullWidth
                      value={fieldVal?.value || ''}
                    >
                      {props.field?.selection?.option0 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option0}>{props?.field?.selection?.option0}</MenuItem>}
                      {props?.field?.selection?.option1 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option1}>{props?.field?.selection?.option1}</MenuItem>}
                      {props?.field?.selection?.option2 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option2}>{props?.field?.selection?.option2}</MenuItem>}
                      {props?.field?.selection?.option3 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option3}>{props?.field?.selection?.option3}</MenuItem>}
                      {props?.field?.selection?.option4 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option4}>{props?.field?.selection?.option4}</MenuItem>}
                      {props?.field?.selection?.option5 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option5}>{props?.field?.selection?.option5}</MenuItem>}
                      {props?.field?.selection?.option6 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option6}>{props?.field?.selection?.option6}</MenuItem>}
                      {props?.field?.selection?.option7 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option7}>{props?.field?.selection?.option7}</MenuItem>}
                      {props?.field?.selection?.option8 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option8}>{props?.field?.selection?.option8}</MenuItem>}
                      {props?.field?.selection?.option9 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option9}>{props?.field?.selection?.option9}</MenuItem>}
                      {props?.field?.selection?.option10 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option10}>{props?.field?.selection?.option10}</MenuItem>}
                      {props?.field?.selection?.option11 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option11}>{props?.field?.selection?.option11}</MenuItem>}
                      {props?.field?.selection?.option12 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option12}>{props?.field?.selection?.option12}</MenuItem>}
                      {props?.field?.selection?.option13 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option13}>{props?.field?.selection?.option13}</MenuItem>}
                      {props?.field?.selection?.option14 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option14}>{props?.field?.selection?.option14}</MenuItem>}
                      {props?.field?.selection?.option15 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option15}>{props?.field?.selection?.option15}</MenuItem>}
                      {props?.field?.selection?.option16 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option16}>{props?.field?.selection?.option16}</MenuItem>}
                      {props?.field?.selection?.option17 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option17}>{props?.field?.selection?.option17}</MenuItem>}
                      {props?.field?.selection?.option18 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option18}>{props?.field?.selection?.option18}</MenuItem>}
                      {props?.field?.selection?.option19 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option19}>{props?.field?.selection?.option19}</MenuItem>}
                      {props?.field?.selection?.option20 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option20}>{props?.field?.selection?.option20}</MenuItem>}
                      {props?.field?.selection?.option21 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option21}>{props?.field?.selection?.option21}</MenuItem>}
                      {props?.field?.selection?.option22 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option22}>{props?.field?.selection?.option22}</MenuItem>}
                      {props?.field?.selection?.option23 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option23}>{props?.field?.selection?.option23}</MenuItem>}
                      {props?.field?.selection?.option24 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option24}>{props?.field?.selection?.option24}</MenuItem>}
                      {props?.field?.selection?.option25 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option25}>{props?.field?.selection?.option25}</MenuItem>}
                      {props?.field?.selection?.option26 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option26}>{props?.field?.selection?.option26}</MenuItem>}
                      {props?.field?.selection?.option27 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option27}>{props?.field?.selection?.option27}</MenuItem>}
                      {props?.field?.selection?.option28 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option28}>{props?.field?.selection?.option28}</MenuItem>}
                      {props?.field?.selection?.option29 != null && <MenuItem classes={{root: classes.root}} value={props?.field?.selection?.option29}>{props?.field?.selection?.option29}</MenuItem>}
                    </Select>
                  </FormControl>
                </>
              )
            case "DOUBLE":
            case "LONG":
            case "CURRENCY":
              return (
                <></>
              )
            case "DATE":
              return (
                <>
                  <MobileDatePicker
                    label={"Choose the Date"}
                    inputFormat="MM/DD/yyyy"
                    error={props.field.required && !props.field.valid}
                    // helperText={(props.field.required && !valid.dateOfBirth) && (props.field.title + "Required.")}
                    value={dob}
                    onAccept={(e) => {handleInputChange({...e, target: {type: "date", value: e}})}}
                    onChange={setDob}
                    fullWidth
                    clearable
                    InputProps={dob != null && {
                      endAdornment: (
                        <IconButton onClick={(e) => {
                          handleInputChange({...e, target: {type:"date",value:null}})
                          setDob(null)
                        }}>
                          <CancelRounded />
                        </IconButton>
                      )
                    }}
                    InputAdornmentProps={{
                      position: "start"
                    }}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth
                        // label={props.field.description}
                        required
                        margin="normal"
                        error={props.field.required && !props.field.valid}
                        // helperText={!valid?.dateOfBirth && "*Date is Required"}
                      />
                    )}
                  />
                </>
              )
            case "DOCUMENT":
              return (
                <>
                  {/* left align not currently working here. */}
                  <Stack xs={12} sm={12} alignItems="left">
                  {(files != null && files.size > 0) &&
                    Array.from(files.values()).map((f) => {
                      return (
                        <IconButton id={f.name} onClick={(e) => handleCancelClick(e,f.name)}>
                          <CancelRounded />
                          {f && f.name}
                        </IconButton>
                      )
                    })
                  } 
                  
                  </Stack>
                  <Stack xs={12} sm={12} alignItems="center">
                    <label htmlFor="contained-button-file">
                      <Input style={{ display: 'none'}} accept="*" id="contained-button-file" type="file" onChange={handleInputChange} aria-describedby={`${props.field.title}-helper-text`} inputProps={{ multiple: true }}/>
                      {/* <IconButton 
                        variant="contained" 
                        size='large' 
                        component="span">
                        <UploadFile /> */}
                        <Button 
                          variant="contained" 
                          size='large' 
                          component="span">
                            <span>Select File(s)</span>
                          {/* <UploadFile /> */}
                        </Button>
                      {/* </IconButton> */}
                    </label>
                  </Stack>
                </>
              )
            default:
              return (
                <></>
              )
          }
        })()}
      </Stack>
    </Box>
  )

}) 