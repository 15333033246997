import { Typography,Grid,Box,Stack, Button } from "@mui/material"
import { connect } from "react-redux"
import { deauthorize } from "../../redux/actions/authActions"
import { reset } from "../../redux/actions/rqsActions"


const mapStateToProps = state => ({
  template: state?.rqs?.activeErqTemplate
})

const mapDispatchToProps = dispatch => ({
  deauth: () => dispatch(deauthorize()),
  reset: () => dispatch(reset())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
  
  return (
    <Box pt={12}>
      <Stack spacing={2} alignItems="center">
        <Grid xs={12}>
        <Typography variant="h3" textAlign="center" gutterBottom>
            Done!
          </Typography>
          <Typography variant="h4" textAlign="center" gutterBottom>
            {/* {props.vaccine?.exempted && "Your exemption proof has been successfully uploaded."}
            {props.vaccine?.vaccinated && "Your vaccine proof has been successfully uploaded."}
            <br/> */}
            You have completed the <br/><b>{props.template.name}</b>.
          </Typography>
          {/* <Typography textAlign="center" variant="h4" gutterBottom>
            Please contact your HR representative if you have any questions or concerns.
          </Typography> */}
        </Grid>
        <Stack direction="row" spacing={2} pt={4}>
          <Button
          variant="contained"
          size="large"
          onClick={props.reset}
          >New Voice</Button>
          <Button 
          variant="contained" 
          size="large"
          color="error"
          onClick={props.deauth}
          >
            EXIT
          </Button>
        </Stack>
     </Stack>
    </Box>
  )
})