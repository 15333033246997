import myAxios from "../../utils/myAxios";
import { url } from "../../utils/ConnectionUtils";


export const GET_VACCINE_SUCCESS = "GET_VACCINE_SUCCESS"
export const GET_VACCINE_FAILURE = "GET_VACCINE_FAILURE"

export const ADD_VACCINE_SUCCESS = "ADD_VACINE_SUCCESS"
export const ADD_VACCINE_FAILURE = "ADD_VACCINE_FAILURE"

export const ADD_TEST_SUCCESS = "ADD_TEST_SUCCESS"
export const ADD_TEST_FAILURE = "ADD_TEST_FAILURE"

export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS"
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE"

export const GET_MANUFACTURERS_SUCCESS = "GET_MANUFACTURERS_SUCCESS"
export const GET_MANUFACTURERS_FAILURE = "GET_MANUFACTURERS_FAILURE"

export const GET_EXCEMPTIONS_SUCCESS = "GET_EXCEMPTIONS_SUCCESS"
export const GET_EXCEMPTIONS_FAILURE = "GET_EXCEMPTIONS_FAILURE"

export const CLEAR_ERROR = "CLEAR_ERROR" 

export const SET_ERROR = "SET_ERROR"

export const setError = err => ({type:SET_ERROR,pyaload:err})

export const getVaccineSuccess = vaccine => ({ type: GET_VACCINE_SUCCESS,payload: vaccine })
export const getVaccineFailure = err => ({ type:GET_VACCINE_FAILURE,payload:err })

export const addVaccineSuccess = vaccine => ({ type:ADD_VACCINE_SUCCESS,payload:vaccine })
export const addVaccineFailure = err => ({ type:ADD_VACCINE_FAILURE,payload:err })

export const addTestSuccess = test => ({ type:ADD_TEST_SUCCESS,payload:test })
export const addTestFailure = err => ({ type:ADD_TEST_FAILURE,payload:err })

export const addDocumentSuccess = document => ({ type:ADD_DOCUMENT_SUCCESS,payload:document })
export const addDocumentFailure = err => ({ type:ADD_DOCUMENT_FAILURE,payload:err })

export const getExcemptionsSuccess = excemptions => ({ type:GET_EXCEMPTIONS_SUCCESS,payload:excemptions })
export const getExcemptionsFailure = err => ({ type:GET_EXCEMPTIONS_FAILURE,payload: err })

export const getManufacturersSuccess = manufacturers => ({ type:GET_MANUFACTURERS_SUCCESS,payload:manufacturers })
export const getManufacturersFailure = err => ({ type:GET_MANUFACTURERS_FAILURE,payload:err })



export const clearError = () => ({ type: CLEAR_ERROR })

export const getManufacturers = () => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/typemaster`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: {
        category: 'vaccine-manufacturer'
      }
    })
    if(response?.data?.message?.ack && response?.data?.message?.TypeMaster != null) {
      dispatch(getManufacturersSuccess(response.data.message.TypeMaster))
    }else if(response?.data?.failure && response?.data?.error != null) {
      dispatch(getManufacturersFailure(response.data.error))
    }else {
      dispatch(getManufacturersFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000mf"))
    }
  }
}

export const getExcemptions = () => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/typemaster`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: {
        category: 'COVID-EXCEMPTION',
        name: null,
        entity: null
      }
    })
    if(response?.data?.message?.ack && response?.data?.message?.TypeMaster != null) {
      dispatch(getExcemptionsSuccess(response.data.message.TypeMaster))
    }else if(response?.data?.failure && response?.data?.error != null) {
      dispatch(getExcemptionsFailure(response.data.error))
    }else {
      dispatch(getExcemptionsFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000ex"))
    }
  }
}

export const getVaccine = (personId) => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/covidvaccinations/${personId}`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'get'
    })
    if(response.data?.message?.ack && response.data?.message?.CovidVaccination != null) {
      dispatch(getVaccineSuccess(response.data?.message?.CovidVaccination[0]))
    }else if(response.data?.message?.CovidVaccination == null) {
      dispatch(addOrUpdateVaccine({entity: {id: null}, personRequest: {id: personId}}, () => {}))
    }else if(response.data?.failure && response.data?.error != null) {
      dispatch(getVaccineFailure(response.error))
    }else {
      dispatch(getVaccineFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000gv"))
    }
  }
}

export const addOrUpdateVaccine = (vaccine, callback) => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/covidvaccination/addupd`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: vaccine
    })
    if(response?.data?.message?.ack && response?.data?.message?.CovidVaccination != null) {
      dispatch(addVaccineSuccess(response.data.message.CovidVaccination))
      callback()
    }else if(response?.data?.failure && response?.data?.error != null) {
      dispatch(addVaccineFailure(response.data.error))
      callback()
    }else {
      dispatch(addVaccineFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000av"))
      callback()
    }
  }
}

export const addTest = (test) => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/covidtest/add`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: test
    })
    if(response?.data?.message?.ack && response?.data?.message?.CovidTest != null) {
      dispatch(addTestSuccess(response?.data?.message.CovidTest))
    }else if(response?.data?.failure && response?.data?.error != null) {
      dispatch(addTestFailure(response?.data?.error))
    }else {
      dispatch(addTestFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000at"))
    }
  }
}

export const addDocument = (accountId,formData,type) => {
  return async dispatch => {
    const response = await myAxios({
      baseURL: `${url}/rqs/fileupload/${accountId}`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData
    })
    if(response?.data?.message?.ack && response.data?.message?.Document != null) {
      dispatch(addDocumentSuccess({id:response.data?.message.Document,type:type}))
    }else if(response.data?.failure && response.data?.error != null) {
      dispatch(addDocumentFailure(response.data?.error))
    }else {
      dispatch(addDocumentFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #000ad"))
    }
  }
}

