import { trackerState } from '../initialState'
import { ADD_DOCUMENT_FAILURE, ADD_DOCUMENT_SUCCESS, ADD_TEST_FAILURE, ADD_TEST_SUCCESS, ADD_VACCINE_FAILURE, ADD_VACCINE_SUCCESS, CLEAR_ERROR, GET_EXCEMPTIONS_FAILURE, GET_EXCEMPTIONS_SUCCESS, GET_MANUFACTURERS_FAILURE, GET_MANUFACTURERS_SUCCESS, GET_VACCINE_FAILURE, GET_VACCINE_SUCCESS, SET_ERROR } from '../actions/trackerActions'


export default (state = trackerState, action) => {
  switch(action.type) {
    case GET_VACCINE_SUCCESS:
      return {
        ...state,
        vaccine: action.payload
      }
    case GET_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case ADD_VACCINE_SUCCESS:
      return {
        ...state,
        vaccine: action.payload
      }
    case ADD_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case ADD_TEST_SUCCESS:
      return {
        ...state,
        vaccine: {
          ...vaccine,
          tests: [...tests || [], action.payload]
        }
      }
    case ADD_TEST_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload
      }
    case ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case GET_EXCEMPTIONS_SUCCESS:
      return {
        ...state,
        excemptions: action.payload
      }
    case GET_EXCEMPTIONS_FAILURE:
      return {
        ...state,
        excemptions: undefined
      }
    case GET_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        manufacturers: action.payload
      }
    case GET_MANUFACTURERS_FAILURE:
      return {
        ...state,
        manufacturers: undefined
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: undefined
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}