import { toBytes, toHex } from "hex-my-bytes"


export const hexEncode = (str) => {
  if(str != null && str.length > 0) {
    let encoder = new TextEncoder()
    let bytes = encoder.encode(str)
    return toHex(bytes)
  }
}

export const hexDecode = (str) => {
  if(str != null && str.length > 0) {
    let decoder = new TextDecoder()
    let bytes = toBytes(str)
    return decoder.decode(bytes)
    
  }
}