import { useState } from 'react'
import { SwipeableDrawer, Box, List, Divider, ListItem, ListItemText, IconButton, Menu, ListItemIcon, ListItemButton } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import HomeIcon from "@mui/icons-material/Home"
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { url } from '../../utils/ConnectionUtils'
import { connect } from 'react-redux'
import { reset, set } from '../../redux/actions/rqsActions'
import { deauthorize } from '../../redux/actions/authActions'
import { Logout } from '@mui/icons-material'

const useStyles = makeStyles({
  list: {
    width: 225,
  }
})

const mapStateToProps = state  => ({

})

const mapDispatchToProps = dispatch => ({
  set: (props) => dispatch(set(props)),
  reset: () => dispatch(reset()),
  deauth: () => dispatch(deauthorize())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  const classes = useStyles()
  const [open,setOpen] = useState(false)

  return (<div>
    <IconButton
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={() => { setOpen(true) }}
    >
      <MenuIcon />
    </IconButton>
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => { setOpen(false) }}
      onOpen={() => { }}
    >
      <div className={classes.list}>
        <Box textAlign="left" p={2}>
          Resources
        </Box>
        <Divider />
        <List>
          <ListItem onClick={() => setOpen(false)} >
            <ListItemButton onClick={(e) => {props.reset()}}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Main Menu"} />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => setOpen(false)} >
           <ListItemButton onClick={() => props.deauth()}>
            <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Quit"} />
           </ListItemButton>
          </ListItem>
          {/* <ListItem button component="a" href={`${url}/rqs/filedownload/100377`}>
            <ListItemText primary={"ExemptionInformation.txt"}/>
          </ListItem> */}
        </List>
      </div>
    </SwipeableDrawer>
  </div>)
})