import { CheckBox } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Stack, TextField, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { useState } from "react";

// const mapStateToProps = state => ({
//   erqRequest: state.rqs?.ErqRequest,
//   templates: state.rqs?.ErqTemplate,
//   activeTemplate: state.rqs?.activeErqTemplate,
//   operations: state.rqs?.ErqTemplateOperation,
//   employee: state.rqs?.Employee,
//   error: state.rqs?.error,
//   fetching: state.rqs?.fetching,
//   employer: state.rqs?.Employer,
//   dialog: state.rqs?.dialog
// })

// const mapDispatchToProps = dispatch => ({
//   get: (props) => dispatch(get(props)),
//   getAll: (props) => dispatch(getAll(props)),
//   update: (props) => dispatch(update(props)),
//   clearError: () => dispatch(clearError()),
//   reset: () => dispatch(reset())
// })

export default connect()((props) => {


  const [openInfo, setOpenInfo] = useState(false)
  const [text, setText] = useState(null)

  const handleSubmit = (e) => {

  } 

  const handleClose = (e) => {

  }

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>
          Notification Setup
          <IconButton onClick={() => setOpenInfo(true)}>
            <InfoIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Would you like to receive notifications? <br />
            You can change this later in the menu.</p>
          </DialogContentText>
          <TextField 
            autoFocus
            label="email"
            variant="standard"
            margin="dense"
            fullWidth
            type="email"
            value={text}
            setText={setText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Subscribe</Button>
          <Button onClick={handleClose}>No Thanks</Button>
        </DialogActions>
      </Dialog>
      <InfoDialog open={openInfo} close={() => setOpenInfo(false)} />
    </>
  )
})



const InfoDialog = (props) => {

  return (
    <Dialog  open={props.open}>
      <DialogTitle>
        Info
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Notifications will include emails sent to confirm your submission was successfully sent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {props.close()}}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}