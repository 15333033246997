import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const createColor = (mainColor) => augmentColor({color:{main: mainColor}})

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#416585', //navy
      light: '#669FD1',
      dark: '#223445',
      contrastText: '#ffffff'
    },
    secondary: {
      // main: '#556cd6',
      main: '#06726E', //teal
      light: '#0ABFB9',
      dark: '#033331',
      contrastText: '#ffffff'
    },
    magenta: {
      // main: '#19857b',
      main: '#9F6283', //magenta
      light: '#EB91C1',
      dark: '#5E3A4E',
      contrastText: '#ffffff'
    },
    // tracker: {
    //   // main: '#ff6961,'
    //   main: '#ff6961',
    //   background: {
    //     default: "#ff6961"
    //   }
    // },
    error: {
      main: red.A400,
    },
  },
});

export default ({children}) => {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
  )
}
