import * as React from 'react';
import { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../components/general/copyright"
import InputMask from "react-input-mask"
import { Alert, FormControl, FormLabel, IconButton, Input, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import { authFailure, authSuccess, clearAuthError } from '../redux/actions/authActions';
import { Navigate, useNavigate } from 'react-router-dom';
import LoadingDialog from '../components/general/LoadingDialog';
import { set } from '../redux/actions/rqsActions';
import { hexEncode } from '../utils/HexUtils';
import { MobileDatePicker } from '@mui/lab';
import { CancelRounded } from '@mui/icons-material';
import moment from 'moment-timezone';

const mapStateToProps = state => ({
  pubk: state.auth?.pubk,
  ep: state.auth?.ep,
  authError: state.auth?.authError
})

const mapDispatchToProps = dispatch => ({
  authSuccess: (person) => dispatch(authSuccess(person)),
  authFailure: (err) => dispatch(authFailure(err)),
  clearError: () => dispatch(clearAuthError()),
  set: (props) => dispatch(set(props))
})

export default connect(mapStateToProps, mapDispatchToProps)((props) => {

  let auth = props.auth()
  let location = props.location()

  let from = location.state?.from?.pathname || "/"


  const ssnRef = React.useRef()
  const [hiddenSSN, setHiddenSSN] = useState()
  const [person, updatePerson] = useState({ firstName: "", lastName: "", ssn: "", dateOfBirth: null, employerId: null })
  const [valid, setValid] = useState({ ssn: true, dateOfBirth: true, ln: true })
  const [employers, setEmployers] = useState(null)
  const [selectedEmployer, setSelectedEmployer] = useState()
  const [dob, setDob] = useState(null)
  const [navigate, setNavigate] = useState()
  const [showLoading, setShowLoading] = useState(false)

  React.useEffect(() => {
    if (auth.authenticated) {
      setNavigate(<Navigate to={from} />)
    }
  })

  let handlePersonUpdate = (person) => {
    updatePerson(person)
  }

  let handleSsnOnChange = (event) => {
    let newSsn = event.target.value
    if (newSsn.length === 9)
      setValid({ ...valid, ssn: true })
    newSsn = newSsn.replaceAll("-", "")
    newSsn = newSsn.replaceAll("_", "")
    //remove letters except for x.
    if(newSsn.length > person.ssn.length) {
      handlePersonUpdate({ ...person, ssn: person.ssn + newSsn.substring(person.ssn.length, newSsn.length).replace(new RegExp("[a-z]", "g"), "")})
    }else {
      handlePersonUpdate({ ...person, ssn: person.ssn.substring(0,newSsn.length)})
    }
    let _newSsn = newSsn.replace(new RegExp("[0-9]", "g"), 'x')
    setHiddenSSN(_newSsn)
  }

  let secondaryHandleSsnChange = (event) => {
    let newSsn = event.target.value
    if (newSsn.length === 9)
      setValid({ ...valid, ssn: true })
    handlePersonUpdate({...person, ssn: newSsn})
  }

  let handleFirstNameOnChange = (event) => {
    if (event.target.value != null)
      setValid({ ...valid, fn: true })
      handlePersonUpdate({ ...person, firstName: event.target.value })
  }

  let handleLastNameOnChange = (event) => {
    if (event.target.value != null)
      setValid({ ...valid, ln: true })
      handlePersonUpdate({ ...person, lastName: event.target.value })
  }

  let handleEmployerChange = (event) => {
    if (event.target.value != null) {
      handlePersonUpdate({ ...person, employerId: event.target.value?.id ?? null })
      setSelectedEmployer(event.target.value)
    }
  }

  let handleDobChange = (dob) => {
    if(dob == null) 
      setDob(null)
    //PREVENT UNDEFINED BEING SET FOR FORMATTING ON INPUT
    dob = dob ?? null
    dob?._d.setHours(0)
    dob?._d.setMinutes(0)
    dob?._d.setSeconds(0)
    //getting a date object
    let now = moment()
    //utcOffset is in minutes
    let localOffset = now.utcOffset()
    //resetting date object to central time
    now.tz("America/Chicago")
    let centralOffset = now.utcOffset()
    let diff = localOffset - centralOffset
    dob?._d?.setHours(dob?._d?.getHours() + (diff/60))

    setValid({...valid, dateOfBirth: (dob != null)})
    handlePersonUpdate({...person, dateOfBirth: dob?._d?.toISOString()})
  }

  let handleSubmitClick = (event) => {
    event.preventDefault()

    if (person?.ssn.length !== 4)
      setValid({ ...valid, ssn: false })
    // else if (person?.firstName.length == 0) {
    //   setValid({ ...valid, fn: false })
    // } 
    else if (person?.lastName.length == 0) {
      setValid({ ...valid, ln: false })
    }
    else if (person?.dateOfBirth == null) {
      setValid({...valid, dateOfBirth: false})
    }
    else {
      //show loading dialog
      setShowLoading(true)
      //login
      auth.signin({ ...person, endpointId: props.ep }, props.pubk, (response, error) => {
        setShowLoading(false)
        if (error || response?.data?.error) {
          //handle error message
          props.authFailure(error || response?.data?.error)
        } else if (response?.data?.message?.Person != null) {
          //handle single person response
          auth.authenticated = true
          let hex = hexEncode(JSON.stringify({ firstName: response.data.message.Person.firstName, lastName: response.data.message.Person.lastName, id: response.data.message.Person.id, employerName: selectedEmployer?.name, 
            accountId: response.data.message.Person.accountId, employerId: selectedEmployer?.id }));
          sessionStorage.setItem("pid", hex)
          props.set({clazz: "Employer", rqs: selectedEmployer ?? undefined})
          props.authSuccess(response.data.message.Person)
          setNavigate(<Navigate to={from} />)
        } else if(response?.data?.message?.Employer != null && response.data.message.Employer.length > 0) {
          auth.authenticated = false
          setEmployers(response?.data?.message?.Employer)
          handlePersonUpdate({ ...person, employerId: response.data.message.Employer[0].id ?? null })
          setSelectedEmployer(response.data.message.Employer[0])
        }
        else {
          console.log('auth failure')
          props.authFailure("Whoops something went wrong. If the problem persists, please contact support. Error Code #001auth")
        }
      })
    }
  }

  let handleDropdownSelectClick = () => {
    if (person.accountId != null) {
      auth.signin(person, props.pubk, (response, error) => {
        if (error) {
          //handle error message
          props.authFailure(error)
        } else if (response?.person != null) {
          //handle single person response
          props.authSuccess(response.person)
          setNavigate(<Navigate to={from} />)
        } else {
          props.authFailure("Whoops, something went wrong. If the problem persists, please contact support. Error Code #003lgn")
        }
      })
    }
  }

  const renderLogin = () => {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          pt={5}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          fullWidth
        >
          {props.authError != null &&
            <Box pt={5}>
              <Alert mt={10} onClose={props.clearError} severity="error">{props.authError}</Alert>
            </Box>
          }
          <Box pt={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            fullWidth
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar> 
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {employers == null &&
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  error={!valid.ln}
                  helperText={!valid.ln && "Last Name is Required."}
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={person?.lastName}
                  onChange={handleLastNameOnChange}
                />
                <InputMask
                  error={!valid.ssn}
                  helperText={!valid.ssn && "Invalid SSN."}
                  type="password"
                  // mask="****"
                  margin="normal"
                  required
                  fullWidth
                  name="ssn"
                  label="Last 4 ssn"
                  id="ssn"
                  autoComplete="ssn"
                  value={hiddenSSN}
                  onChange={handleSsnOnChange}
                  inputRef={ssnRef}
                  inputProps={{ maxLength: 4}}
                >
                  {((inputProps) => {
                    return <TextField {...inputProps} />;
                  })()}
                </InputMask>
                <MobileDatePicker
                  label="Date of Birth"
                  inputFormat="MM/DD/yyyy"
                  error={!valid.dateOfBirth}
                  helperText={!valid.dateOfBirth && "DOB is Required."}
                  value={dob}
                  onAccept={handleDobChange}
                  onChange={setDob}
                  fullWidth
                  clearable
                  InputProps={person?.dateOfBirth != null && {
                    endAdornment: (
                      <IconButton onClick={() => handleDobChange(null)}>
                        <CancelRounded />
                      </IconButton>
                    )
                  }}
                  InputAdornmentProps={{
                    position: "start"
                  }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth
                      label="Date of Birth"
                      required
                      margin="normal"
                      error={!valid?.dateOfBirth}
                      helperText={!valid?.dateOfBirth && "*DOB is Required"}
                    />
                  )}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmitClick}
                >
                  Submit
                </Button>
              </Box>
            }
            {employers?.length > 0 &&
              <Box pt={4} component="form" fullWidth>
                {/* <FormControl>
                  <FormLabel>Choose Employer</FormLabel>
                </FormControl> */}
                <FormControl mt={0} variant="standard" fullWidth>
                  {/* <FormLabel>Vaccine Information:</FormLabel> */}
                  <InputLabel id="employer-label">Choose Employer</InputLabel>
                  <Select
                    labelId="employer-label"
                    label="Choose Employer"
                    onChange={handleEmployerChange}
                    value={selectedEmployer}
                    defaultValue={employers[0]}
                    fullWidth
                  >
                    {employers.map((er) => {
                      return <MenuItem fullWidth value={er}>{er.name}</MenuItem>
                    }
                    )}
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmitClick}
                >
                  Select
                </Button>
              </Box>
            }
          </Box>
        </Box>
        <LoadingDialog open={showLoading} title="Searching Records" info="Please wait, this may take a moment." />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    )
  }

  return (
    navigate == null ? renderLogin() : navigate
  )

})