import { Typography } from "@mui/material";
import { Link } from "@mui/material";

export default (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.eligibilitytrackingcalculators.com"rel="noopener noreferrer" target="_blank">
        ETC Companies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}