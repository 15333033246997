import { CLEAR_DIALOG, CLEAR_ERROR, RESET, RQS_BEGIN, RQS_FAILURE, RQS_SUCCESS, SET, SET_DIALOG, SET_ERROR } from '../actions/rqsActions';
import { rqsState } from '../initialState'


export default (state = rqsState, action) => {
  // console.log('rqs reducer: action: ', action)
  switch(action.type) {
    case RQS_BEGIN:
      return {
        ...state,
        fetching: true
      }
    case RQS_SUCCESS:
      return {
        ...state,
        fetching: false,
        [action.payload.clazz]: action.payload.rqs
      }
    case RQS_FAILURE:
      return {
        ...state,
        fetching:false,
        error: action.payload.err
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: undefined
      }
    case SET_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload
      }
    case SET:
      return {
        ...state,
        [action.payload.clazz]:action.payload.rqs
      }
    case RESET:
      return {}
    case SET_DIALOG:
      return {
        ...state,
        fetching: true,
        dialog: action.payload
      }
    case CLEAR_DIALOG:
      return {
        ...state,
        fetching: false,
        dialog: undefined
      }
    default: 
      return state;
  }
}