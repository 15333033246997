import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { set, get, getAll, addOrGetErqRequestStep, setDialog, clearDialog } from "../../redux/actions/rqsActions"
import { Box, Button, createTheme, Stack, Typography } from "@mui/material"
import TemplateField from "./TemplateField"
import { add, addDocument, request, update } from "../../utils/ConnectionUtils"
import { getPerson } from "../../utils/localDataUtils"
import { ThemeProvider } from "@mui/styles"


const mapStateToProps = state => ({
  templates: state.rqs?.ErqTemplate,
  activeTemplate: state.rqs?.activeErqTemplate,
  fields: state.rqs?.ErqTemplateField,
  erqRequest: state.rqs?.ErqRequest,
  erqRequestStep: state.rqs?.ErqRequestStep,
  error: state.rqs?.error,
  fetching: state.rqs?.fetching
})

const mapDispatchToProps = dispatch => ({
  set: (props) => dispatch(set(props)),
  get: (props) => dispatch(get(props)),
  getAll: (props) => dispatch(getAll(props)),
  addOrGetErqRequestStep: (props) => dispatch(addOrGetErqRequestStep(props)),
  setDialog: (props) => dispatch(setDialog(props)),
  clearDialog: () => dispatch(clearDialog())
})


export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  const [fieldMap, setFieldMap] = useState()
  const [fieldValMap, setFieldValMap] = useState(new Map())
  const [valid, setValid] = useState(false)
  const [operation,setOperation] = useState(null)
  

  
  useEffect(() => {
    if(props.error == null && !props.fetching) {
      //grab the updated ErqTemplateOperation if the local copy is null or id's do not match
      if(operation == null || operation.id !== props.operation.id) {
        if(operation !== null)
          console.log("resetting the operation.")
        props.set({clazz:"ErqRequestStep",rqs:undefined})
        setOperation(props.operation)
      }
      //grab the operation's ErqTemplateFields if they are null or if the operation has changed
      else if((props.fields == null || (props.fields.length > 0 && props.fields[0].operationId !== operation.id))) {
        //load ErqTemplateFields for the ErqTemplateOperation
        props.getAll({clazz:"ErqTemplateField",jsonObj:{operationId: props.operation.id},path:"cv2"})
        setFieldMap(null)
      }
      //build the Map of the ErqTemplateFields, using their id as the key
      else if(props.fields != null && (fieldMap == null)) {
        let map = new Map()
        for(let i=0;i<props.fields.length;i++) {
          map.set(props.fields[i].id, {...props.fields[i],valid:false})
        }
        setFieldMap(map)
      }
      //LOAD ErqStepRequest for this operation, or create new one
      else if((props.erqRequestStep == null || props.erqRequestStep.operationId != operation.id) && props.erqRequest != null) {
        props.addOrGetErqRequestStep({operationId: props.operation.id, requestId: props.erqRequest.id})
      }
    }
  })

  /**
   * async variant of the Array.every method. If the provided predicate method 
   * returns false, we immediately short-circuit, otherwise, return true if all 
   * elements satisfy.
   * @param {*} arr Array
   * @param {*} predicate Function to test elements
   * @returns boolean   
   */
  const asyncEvery = async (arr, predicate) => {
    for(let element of arr) {
      if(!await predicate(element)) return false
    }
    return true
  }

  /**
   * handler passed to children to update whether or not the 
   * relevant ErqTemplateField input is valid. If the input is 
   * required and the input is not empty, in most cases the input 
   * is then considered valid. 
   * @param {*} id the ErqTemplateField id, and the key
   * @param {*} valid whether or not this ErqTemplateField is valid
   */
  const handleSetValid = (id,valid) => {
    fieldMap.set(id, {...fieldMap.get(id), valid: valid})
    let val = true
    Array.from(fieldMap.values()).every((field) => {
      if(field.required)
        return (val = field.valid)
      else 
        return true
    })
    setValid(val)
    setFieldMap(fieldMap)
  }

  /**
   * handler passed to children to update the ErqRequestFieldValue map.
   * callback allows any work necessary to be performed in children after 
   * this process has completed.
   * @param {*} id the ErqRequestFieldValue id, and the key
   * @param {*} val the updated ErqRequestFieldValue, and the value
   * @param {*} callback the callback hook for children
   */
  const handleUpdateFieldValues = (id, val, callback) => {
    fieldValMap.set(id, val)
    setFieldValMap(new Map(fieldValMap))
    callback()
  }
  
  /**
   * handler for the 'next' or 'finish' button click.
   * Updates the ErqRequestFieldValues. The callback is the 
   * container's hook to progress to the next Operation or 'page'
   * or to complete the ErqRequest and finish the form.
   * @param {*} event the button's event
   * @param {*} callback the container's hook callback method
   */
  const onButtonClick = async (event, callback) => {
    event.preventDefault()
    if(fieldValMap != null && fieldValMap.size > 0) {
      const _updated = await asyncEvery(Array.from(fieldValMap.values()), async (fieldVal) => {
        // let upd = true
        if(fieldVal?.field?.type) {
          switch(fieldVal.field.type) {
            case "DATE":
            case "STRING_LIST":
            case "LARGE_STRING":
            case "STRING":
              props.setDialog({title:"Saving Data",info:"Please wait, this may take a moment."})
              return await update({clazz:"ErqRequestFieldValue",jsonObj:{id:fieldVal.id, entity:{...fieldVal}},path:"cv2"})
              .then((val) => { 
                props.clearDialog()
                return (val != null)
              })
              .catch((err) => { 
                console.log(err)
                props.clearDialog()
                return false
              })
            case "DOCUMENT":
              let fdata = new FormData()
              if(fieldVal.files == null) {
                if(fieldVal?.field?.required) {
                  return false
                }else {
                  return true
                }
              }else {
                let upd
                for(let file of fieldVal.files) {
                  if(file == null) {
                    props.set({clazz:"error",rqs:"Whoops, something went wrong. If the problem persists, please contact support. Code #001cv2d"})
                    return false
                  }
                  props.setDialog({title:"Saving Data",info:"Please wait, this may take a moment."})
                  fdata.set('cv2-document', file)
                  fdata.set('documentTypeId', fieldMap.get(fieldVal?.fieldId)?.documentTypeId ?? 0)
                  fdata.set('uploadTypeId', 17409)
                  fdata.set('employerId', getPerson()?.employerId ?? 0)
                  // upload file, get document.id. Maybe get documentType as well.
                  props.set({clazz:"fetching", rqs:true})
                  upd = await addDocument({accountId:getPerson()?.accountId, formData:fdata})
                  .then(async (val) => {
                    return await add({clazz:"ErqRequestFieldValueDocument", jsonObj:{entity: {fieldId:fieldVal.id, documentId:val.id}}, path:"cv2"})
                    .then((val) => { 
                      props.clearDialog()
                      return true
                    })
                    .catch((err) => {
                      props.set({clazz:"error",rqs:err.message})
                      props.clearDialog()
                      return false
                    })
                  })
                  .catch((err) => { 
                    props.set({clazz:"error",rqs:err.message})
                    props.clearDialog()
                    return false
                  })
                  if(!upd) return false
                }
                return true
              } 
          }
        }
      })
      if(!_updated) {
        props.set({clazz:"error",rqs:"Whoops, something went wrong. If the problem persists, please contact support. Code #005upa"})
        props.clearDialog()
      }else callback(event)
    }
  }

  return (
    <>
      {props.erqRequestStep != null && 
      <Box pt={10}>
        <Stack spacing={3} xs={12} sm={6} alignItems="left">
          <Typography variant="h4" textAlign="center" gutterBottom>
            {props.activeTemplate?.name}
          </Typography>
          {fieldMap != null &&
            Array.from(fieldMap.values()).map((field) => {
              if(field.active)
                return <TemplateField field={field} step={props.erqRequestStep} valid={valid} setValid={handleSetValid} update={handleUpdateFieldValues}  />
            })
          }
        </Stack>
        <>{!props.fetching && 
          <MyButtons 
            onButton1={(e) => {
              props.button1(e)
            }}
            onButton2={(e) => {
              onButtonClick(e,props.button2)
            }}
            // button1Disabled={props.opIdxInfo.idx == 0}
            button2Disabled={!valid}
            button1={"Back"}
            button2={props.opIdxInfo.length > (props.opIdxInfo.idx + 1) ? "Next" : "Finish"}
          />}
        </>
      </Box>
      }
    </>
  )
})

/**
 * Button group component used to control navigation 
 * within the 'Operation' or pages, and completing the 
 * form if all pages are viewed.
 * @param {*} props 
 * @returns 
 */
const MyButtons = (props) => {

  return (
    <Box justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={2} xs={12} sm={12} pt={6} justifyContent="center" alignItems="center">
      <Button 
      disabled={props.button1Disabled}
      variant="contained" 
      size="large"
      onClick={props.onButton1}
      >
        {props.button1}
      </Button>
      <Button 
      disabled={props.button2Disabled}
      variant="contained" 
      size="large"
      onClick={props.onButton2}
      >
        {props.button2}
      </Button>
    </Stack>
    </Box>
  )
}