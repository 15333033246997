import { authState } from '../initialState'
import { KEY_FAILURE, KEY_SUCCESS, EP_SUCCESS, EP_FAILURE, AUTH_SUCCESS, AUTH_FAILURE, CLEAR_ERROR, CLEAR_AUTH_ERROR, AUTHORIZE_SUCCESS, AUTHORIZE_FAILURE, DEAUTHORIZE_SUCCESS } from '../actions/authActions'

export default (state = authState, action) => {
  switch(action.type) {
    case KEY_SUCCESS:
      return {
        ...state,
        pubk: action.payload
      }
    case KEY_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case EP_SUCCESS:
      return {
        ...state,
        ep: action.payload
      }
    case EP_FAILURE: 
      return {
        ...state,
        error: action.payload
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        authorize: true,
        person: action.payload,
      }
    case AUTH_FAILURE:
      return {
        ...state,
        authorize: false,
        authError: action.payload
      }
    case DEAUTHORIZE_SUCCESS:
      return {
        ...state,
        authorize: false
      }
    case AUTHORIZE_SUCCESS:
      return {
        ...state,
        authorize: true
      }
    case AUTHORIZE_FAILURE:
      return {
        ...state,
        authorize: false
      }
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        authError: undefined
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: undefined
      }
    default:
      return state
  }
}