import { hexDecode } from "./HexUtils"


export const getPerson = () => {
  if(sessionStorage.getItem('pid') != null) {
    return JSON.parse(hexDecode(sessionStorage.getItem("pid")))
  }else 
    return false
}

export const isLoading = (fetching) => {
  if(typeof(fetching) == 'object') {
    return Array.from(Object.values(fetching)).every((v) => {
      return v
    })
  }else
    return false
}