import CoreResponse from "./CoreResponse"
import myAxios from "./myAxios"

export const url = process.env.REACT_APP_PROD ? "https://ws.corvetto.eevoice.com/cv2" : "https://dev.ws.corvetto.eevoice.com/cv2"




/**
 * adds the provided entity
 * @param {*} props {clazz, jsonObj, path}
 * @returns added entity
 */
 export const add = async (props) => {
  let rsp
  if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
    if((rsp = new CoreResponse(await request({...props, optp: 'ADD'}), props?.clazz)) != null && rsp.isValid()) {
      if(rsp.isError()) {
        throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #001a")
      }else
        return rsp.data
    }else
      throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #002a")
  }else
    throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #003a")
}

/**
 * updates the provided entity
 * @param {*} props {clazz, jsonObj, path}
 * @returns updated entity
 */
export const update = async (props) => {
  let rsp
  if(props?.clazz != null && props?.jsonObj != null && props?.path != null) {
    if((rsp = new CoreResponse(await request({...props, optp: 'IDX_UPD'}), props?.clazz)) != null && rsp.isValid()) {
      if(rsp.isError()) {
        throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #001ufv")
      }else
        return rsp.data
    }else
      throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #002ufv")
  }else
    throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #003ufv")
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const addOrGetErqRequestStep = async (props) => {
  let rsp
  if((rsp = new CoreResponse(await request({clazz: "ErqRequestStep", jsonObj: {operationId:props.operationId}, path: "cv2", optp: "ALL"}), "ErqRequestStep")) != null && rsp.isValid()) {
    if(!rsp.isError() && rsp.data.length == 0) {
      //add rqs
      if((rsp = new CoreResponse(await request({clazz: "ErqRequestStep", jsonObj: {entity: {operationId:props.operationId}}, path: "cv2", optp: "ADD"}), "ErqRequestStep")) != null && rsp.isValid()) {
        if(rsp.isError()) {
          throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #001erqst")
        }else 
          return rsp.data
      }else 
        throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #002erqst")
    }else 
      return rsp.data[0]
  }else 
  throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #004erqst")
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const addOrGetErqRequestFieldValue = async (props) => {
  let rsp
  if((rsp = new CoreResponse(await request({clazz: "ErqRequestFieldValue", jsonObj: {stepId: props.stepId, fieldId: props.fieldId}, path: "cv2", optp: "ALL"}), "ErqRequestFieldValue")) != null && rsp.isValid()) {
    if(!rsp.isError() && rsp.data.length == 0) {
      if((rsp = new CoreResponse(await request({clazz: "ErqRequestFieldValue", jsonObj: {entity: {stepId: props.stepId, fieldId: props.fieldId}}, path: "cv2", optp: "ADD"}), "ErqRequestFieldValue")) != null && rsp.isValid()) {
        if(rsp.isError()) {
          throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #001erqfv")
        }else 
          return rsp.data
      }else 
        throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #002erqfv")
    }else 
      return rsp.data[0]
  }else 
  throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #004erqfv")
}

/**
 * addDocument creates a new Document record by the accountId 
 * provided. 
 * @param {*} accountId the accountId 
 * @param {FormData} formData cv2-document (the file), type-id (documentType id)
 * @returns dispatch
 */
export const addDocument = async (props) => {
  let rsp
  const response = await myAxios({
    baseURL: `${url}/rq/fileupload/${props.accountId}`,
    crossDomain: true,
    timeout: 200000,
    withCredentials: true,
    responseType: 'json',
    method: 'post',
    headers: { 'content-type': 'multipart/form-data' },
    data: props.formData
  })
  if((rsp = new CoreResponse(response.data, "Document")) != null && rsp.isValid()) {
    if(rsp.isError()) 
      throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #001upfl")
    else
      return rsp.data
  }else
    throw new Error("Whoops something went wrong. If the problem persists, please contact support. Code #002upfl")
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const request = async (props) => {
  let resp = {err: null, rq: null, failure: true};
  if(props != null) {
    const response = await myAxios({
      baseURL: `${url}/rq/${props?.path}/${props?.clazz}Request:${props?.optp}`,
      crossDomain: true,
      timeout: 200000,
      withCredentials: true,
      responseType: 'json',
      method: 'post',
      data: props.jsonObj
    })
    return response?.data;
  }
}