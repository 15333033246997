import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { get, getAll, set } from "../../redux/actions/rqsActions"
import { Box, Button, FormControl, FormLabel, InputLabel, MenuItem, Select, Typography } from "@mui/material"


const mapStateToProps = state => ({
  templates: state.rqs?.ErqTemplate,
  activeTemplate: state.rqs?.activeErqTemplate,
  error: state.rqs?.error
})

const mapDispatchToProps = dispatch => ({
  set: (props) => dispatch(set(props)),
  get: (props) => dispatch(get(props)),
  getAll: (props) => dispatch(getAll(props))
})


export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  useEffect(() => {
    if(props.error == null) {
      if(props.templates == null) {
        props.getAll({jsonObj: {id: null, entity: null},clazz:"ErqTemplate",path:"cv2"}); //possibly pull all fields for all templateOperations and re-assemble them.
      }
    }
  })

  const [selectedTemplate, setSelectedTemplate] = useState(null)


  const handleTemplateChange = (event) => {
    if(event.target.value != null)
      setSelectedTemplate(event.target.value)
  }

  const handleSubmitClick = (event) => {
    event.preventDefault()
    props.set({clazz:"activeErqTemplate", rqs: selectedTemplate})
  }

  return (
    <Box pt={10}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Select your Voice
      </Typography>
      {props.templates != null &&
      <Box pt={4}>
        <FormControl mt={0} variant="standard" fullWidth>
          <InputLabel id="template-label">Voice Options</InputLabel>
          <Select
            labelId="template-label"
            label="template"
            onChange={handleTemplateChange}
            fullWidth
            value={selectedTemplate}
          >
            {props.templates.map((template) => {
              return <MenuItem value={template}>{template.name}</MenuItem>
            }
            )}
          </Select>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmitClick}
        >
          Select
        </Button>
      </Box>
      }
    </Box>
  )
})