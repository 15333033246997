import React, { useState } from "react"
import { IconButton, MenuItem, Menu, ListItemIcon, ListItemText, ListItem, ListItemButton, Button } from "@mui/material"
import { AccountCircle, Logout } from "@mui/icons-material"
import { deauthorize } from "../../redux/actions/authActions"
import { connect } from 'react-redux';
import { getPerson } from "../../utils/localDataUtils";
import PersonIcon from '@mui/icons-material/Person'


const mapStateToProps = state => ({
  authz: state.auth?.authorize
})

const mapDispatchToProps = dispatch => ({
  deauth: () => dispatch(deauthorize())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const handleMenu = (event) => {
    setAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setAnchor(null)
  }

  return <div>
    <Button variant="text" 
    sx={{color: 'white', bgcolor: 'secondary.main'}} 
    endIcon={<Logout />}
    onClick={() => props.deauth()}
    >
      exit
    </Button>
    {/* <ListItem onClick={() => {}} >
      <ListItemButton onClick={() => props.deauth()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={"Quit"} />
      </ListItemButton>
    </ListItem> */}
    <Menu
      id="menu-appbar"
      anchorEl={anchor}
      anchorOrigin={
        {
          vertical: "top",
          horizontal: "right"
        }
      }
      keepMounted
      transformOrigin={
        {
          vertical: "top",
          horizontal: "right"
        }
      }
      open={open}
      onClose={handleClose}
    >
      {/* <MenuItem>Profile</MenuItem>
      <MenuItem>My Account</MenuItem> */}
      <MenuItem >
        <ListItemIcon>
          <PersonIcon/>
        </ListItemIcon>
        <ListItemText primary={getPerson()?.firstName + " " + getPerson()?.lastName} />
      </MenuItem>
    </Menu>

  </div>
})