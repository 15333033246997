import { CircularProgress, Dialog, DialogTitle, Grid, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"




export default (props) => {


  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <Stack spacing={3} p={2} xs={12} sm={6} alignItems="center">
        <Typography>
          {props.info}
        </Typography>
        <Box>
          <CircularProgress />
        </Box>
      </Stack>
    </Dialog>
  )
}