import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { TemplateSelection,TemplateOperation,ThankYou,ProfilePrompt } from "../components/eevoice"
import { addOrGetErqRequest, clearError, get, getAll, reset, update, submitEEVoice } from "../redux/actions/rqsActions"
import { Alert, Box, Collapse, Stack } from "@mui/material"
import LoadingDialog from "../components/general/LoadingDialog"
import { hexDecode } from "../utils/HexUtils"



const mapStateToProps = state => ({
  erqRequest: state.rqs?.ErqRequest,
  templates: state.rqs?.ErqTemplate,
  activeTemplate: state.rqs?.activeErqTemplate,
  operations: state.rqs?.ErqTemplateOperation,
  employee: state.rqs?.Employee,
  error: state.rqs?.error,
  fetching: state.rqs?.fetching,
  employer: state.rqs?.Employer,
  dialog: state.rqs?.dialog,
  erqProfile: state.rqs?.ErqProfile
})

const mapDispatchToProps = dispatch => ({
  get: (props) => dispatch(get(props)),
  getAll: (props) => dispatch(getAll(props)),
  update: (props) => dispatch(update(props)),
  submitEEVoice: (props) => dispatch(submitEEVoice(props)),
  addOrGetErqRequest: (props) => dispatch(addOrGetErqRequest(props)),
  clearError: () => dispatch(clearError()),
  reset: () => dispatch(reset())
})


export default connect(mapStateToProps,mapDispatchToProps)((props) => {


  const [currentOperation, setCurrentOperation] = useState(null)
  const [idxInfo, setIdxInfo] = useState(null)
 

  useEffect(() => {
    if(props.error == null && !props.fetching) {
      //load ErqProfile
      if(props.erqProfile == null) {
        props.getAll({jsonObj:{personId: JSON.parse(hexDecode(sessionStorage.getItem("pid")))?.id},clazz:"ErqProfile",path:"cv2"})
      }
      //load template list
      else if(props.templates == null) {
        props.getAll({jsonObj: {id: null, entity: null, accountId: JSON.parse(hexDecode(sessionStorage.getItem("pid")))?.accountId},clazz:"ErqTemplate",path:"cv2"});
      }
      else if(props.employee == null) {
        //load Employee (used for ErqRequest)
        let p = JSON.parse(hexDecode(sessionStorage.getItem("pid")))
        props.getAll({clazz: "Employee", jsonObj: {
          fetchInactive: true,
          employerId: props.employer.id,
          personId: p.id
        }, path: "cv2"})
      }
      else if(props.activeTemplate != null && props.employee != null && props.erqRequest == null) {
        //load ErqRequest
        props.addOrGetErqRequest({employeeId:props.employee[0].id, templateId:props.activeTemplate.id}) 
      }
      //IF THE OPERATION'S TEMPLATE ID DOES NOT MATCH THE CURRENT TEMPLATE OR THE OPERATIONS ARE EMPTY, LOAD THEM.
      else if(((props.operations != null && props.operations[0] != null && props.operations[0].templateId != props.activeTemplate?.id) || props.operations == null && props.activeTemplate != null)) {
        //load ErqTemplateOperations for the selected ErqTemplate
        props.getAll({jsonObj: {id: null, templateId: props.activeTemplate.id}, clazz: "ErqTemplateOperation",path:"cv2"})
        //MUST RESET THE IDXINFO SO THAT THE BELOW BLOCK WILL RUN AND RE-INITIALIZE OPERATION AND CHILD OBJECTS
        if(idxInfo != null) setIdxInfo(null)
      } 
      //initialize the idxInfo obj. which contains the current operation index and the length
      //set the current operation (page)
      else if(props.operations != null && idxInfo == null) {
        //LOAD THE LAST OPERATION (PAGE) IF IT WAS SET, ONLY AVAILABLE FOR PREVIOUSLY LOADED BUT NOT COMPLETED FORMS.
        let lastOp
        if(props.erqRequest?.lastOperationId != null) {
          for(let op of props.operations) {
            if(op.id == props.erqRequest.lastOperationId) {
              lastOp = op
              break
            }
          }
        }
        //SET PAGE INDEX
        setIdxInfo({
          idx: props.operations.indexOf(lastOp) > -1 ? props.operations.indexOf(lastOp) : 0,
          length: props?.operations?.length
        })
        setCurrentOperation(lastOp ?? props.operations[0])
      }
    }
  })

  /**
   * handler for controlling operation 'page' navigation.
   * Button1 is the 'back' button. The idx is decremented.
   * @param {*} event the button's event
   */
  const handleButton1 = (event) => {
    event.preventDefault()
    if(idxInfo?.idx > 0) {
      updateOpIdxInfo(idxInfo.idx - 1)
    }else {
      props.reset()
    }
  }

   
  /**
   * handler for controlling operation 'page' navigation.
   * Button2 is the 'next' or 'finish' button. If the last 
   * page is already loaded, when this handler is called next 
   * the ErqRequest is completed, otherwise the idx is incremented.
   * @param {*} event 
   */
  const handleButton2 = (event) => {
    event.preventDefault()
    //complete the ErqRequest
    if((idxInfo.idx + 1) == props.operations.length) {
      // props.update({clazz:"ErqRequest",jsonObj:{id:props.erqRequest.id, entity:{...props.erqRequest, submitted:true, submittedOn: new Date().getTime()}},path:"cv2"})
      // props.get({clazz:"SubmitEEVoice",jsonObj:{entity:{erqRequestId:props.erqRequest.id,submittedOn: new Date().getTime()}},path:"cv2"})
      props.submitEEVoice({jsonObj:{entity:{erqRequestId:props.erqRequest.id,submittedOn: new Date().getTime()}}});
    }
    //progress to the next operation (page)
    else {
      props.update({clazz:"ErqRequest",jsonObj:{id:props.erqRequest.id, entity:{...props.erqRequest, lastOperationId: props.operations[idxInfo?.idx + 1].id}},path:"cv2"})
      updateOpIdxInfo(idxInfo.idx + 1)
    }
  }

 /**
  * updates the operation idx and sets the currentOperation.
  * the passed in index is set to the idxInfo's idx property, 
  * or 0. The currentOperation or 'page' is then set using this 
  * value.
  * @param {*} idx the passed in index value
  */
  const updateOpIdxInfo = (idx) => {
    let info = {
      idx: idx ?? 0,
      length: props?.operations?.length ?? 0
    }
    setIdxInfo(info)
    setCurrentOperation(props?.operations[info.idx])
  }


  return (
    <div>
      <Stack direction="column" xs={12}>
      <Collapse in={props.error}>
        <Box pt={5}>
          <Alert mt={10} onClose={() => {
            props.clearError()
          }} severity="error">{props.error}</Alert>
        </Box>
      </Collapse>
      <Box alignItems="center">
        {/* {(props.erqProfile == null || props.erqProfile.length == 0) && 
          <ProfilePrompt open={true} fullWidth={true} />
        } */}
        {(props.templates != null && props.activeTemplate == null) &&
          <TemplateSelection />
        }
        {(props.operations != null && props.erqRequest != null) &&
        (!props.erqRequest.submitted && currentOperation != null && idxInfo != null && 
          <TemplateOperation operation={currentOperation} opIdxInfo={idxInfo} button1={handleButton1} button2={handleButton2} />
        )} 
        {props?.erqRequest?.submitted &&
          <ThankYou />
        }
      </Box>
    </Stack>
    {/* <>{buttons}</> */}
    <LoadingDialog open={props.fetching} title={props.dialog?.title ?? "Searching Records"} info={props.dialog?.info ?? "Please wait, this may take a moment."} />
    </div>
  )
})