import axios from 'axios'

const myAxios = axios 
myAxios.defaults.withCredentials = true
myAxios.interceptors.request.use(request => {
 
  return request
})

myAxios.interceptors.response.use(response => {
  
  return response
}, error => {
  if(process.env.REACT_APP_LOG)
    console.log("Error: ", JSON.stringify(error.response))
  if (error.response.status === 401) {
    sessionStorage.removeItem('pid')
    window.location.reload()
 }
 return error.response;
})

export default myAxios