import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Account from "./Account"
import Drawer from "./Drawer"
import { useTheme } from "@mui/material"
import { connect } from "react-redux"
import { useEffect } from "react"
import { set } from "../../redux/actions/rqsActions"
import { hexDecode } from "../../utils/HexUtils"

const logo = window.location.origin + "/etc_white.png"

const mapStateToProps = state => ({
  authz: state.auth?.authorize,
  template: state.rqs?.ErqTemplate,
  employer: state.rqs?.Employer
})

const mapDispatchToProps = dispatch => ({
  set: (props) => dispatch(set(props))
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  const theme = useTheme()

  useEffect(() => {
    if(props.employer == null) {
      let person
      if(sessionStorage.getItem("pid") != null && (person = JSON.parse(hexDecode(sessionStorage.getItem("pid")))) != null) {
        let emp = {
          id: person?.employerId,
          name: person?.employerName ?? "No Employer Found"
        }
        props.set({clazz:"Employer", rqs:emp})
      }
    }
  })

  return (
    <AppBar sx={{ bgcolor: 'secondary.main' }}>
      <Toolbar>
        
        {props.authz && <Drawer/>}
        <Box 
          component="img"
          sx={{
            height: 45
          }}
          alt="EE Voice"
          src={logo}
        />
        {/* SPACER TO GROW ACROSS HEADER. USED TO PLACE EXIT BUTTON ON FAR RIGHT */}
        <Box flexGrow={1} />
        {/* <Box flexGrow={1} >
          <Typography variant="h6">{props.employer != null ? `${props.employer.name}` : 'EEVoice'}</Typography>
        </Box> */}
        {props.authz && <Account />}
      </Toolbar>
    </AppBar>
  )
})